
import { JobProfileModel } from "@common/domain/models/JobProfileModel";
import { NSDCInstituteModel } from "@common/domain/models/NSDCInstituteModel";
import { PssciveCourse } from "@common/domain/models/PssciveCourse";
import { IFluxStandardAction } from "@store/webInterfaces";

const actionTypePrefix = "CAREERADVISOR/";

export const careerAdvisorActions = {
  FETCH_JOB_ROLES: `${actionTypePrefix}FETCH_JOB_ROLES`,
  FETCH_JOB_ROLES_SUCCESS: `${actionTypePrefix}FETCH_JOB_ROLES_SUCCESS`,
  FETCH_JOB_ROLES_FAIL: `${actionTypePrefix}FETCH_JOB_ROLES_FAIL`,

  UPDATE_FILTER_VALUES: `${actionTypePrefix}UPDATE_FILTER_VALUES`,

  FETCH_JOBROLE_BY_ID: `${actionTypePrefix}FETCH_JOBROLE_BY_ID`,
  FETCH_JOBROLE_BY_ID_SUCCESS: `${actionTypePrefix}FETCH_JOBROLE_BY_ID_SUCCESS`,
  FETCH_JOBROLE_BY_ID_FAIL: `${actionTypePrefix}FETCH_JOBROLE_BY_ID_FAIL`,

  ADD_OR_REMOVE_FAVOURITE: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE`,
  ADD_OR_REMOVE_FAVOURITE_SUCCESS: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE_SUCCESS`,
  ADD_OR_REMOVE_FAVOURITE_FAIL: `${actionTypePrefix}ADD_OR_REMOVE_FAVOURITE_FAIL`,

  GET_FAVOURITE_KEYS: `${actionTypePrefix}GET_FAVOURITE_KEYS`,
  GET_FAVOURITE_KEYS_SUCCESS: `${actionTypePrefix}GET_FAVOURITE_KEYS_SUCCESS`,
  GET_FAVOURITE_KEYS_FAIL: `${actionTypePrefix}GET_FAVOURITE_KEYS_FAIL`,

  UPDATE_CAREER_PREFERENCE: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE`,
  UPDATE_CAREER_PREFERENCE_SUCCESS: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE_SUCCESS`,
  UPDATE_CAREER_PREFERENCE_FAIL: `${actionTypePrefix}UPDATE_CAREER_PREFERENCE_FAIL`,

  FETCH_CAREER_PREFERENCE: `${actionTypePrefix}FETCH_CAREER_PREFERENCE`,
  FETCH_CAREER_PREFERENCE_SUCCESS: `${actionTypePrefix}FETCH_CAREER_PREFERENCE_SUCCESS`,
  FETCH_CAREER_PREFERENCE_FAIL: `${actionTypePrefix}FETCH_CAREER_PREFERENCE_FAIL`,

  FETCH_ATTRIBUTE_GROUPS: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS`,
  FETCH_ATTRIBUTE_GROUPS_SUCCESS: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS_SUCCESS`,
  FETCH_ATTRIBUTE_GROUPS_FAIL: `${actionTypePrefix}FETCH_ATTRIBUTE_GROUPS_FAIL`,

  SUBMIT_ATTRIBUTE_USER_RESPONSE: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE`,
  SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS`,
  SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL: `${actionTypePrefix}SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL`,

  DELETE_ATTRIBUTE_USER_RESPONSE: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE`,
  DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS`,
  DELETE_ATTRIBUTE_USER_RESPONSE_FAIL: `${actionTypePrefix}DELETE_ATTRIBUTE_USER_RESPONSE_FAIL`,

  GET_QUESTIONNAIRE_RESPONSE: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE`,
  GET_QUESTIONNAIRE_RESPONSE_SUCCESS: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE_SUCCESS`,
  GET_QUESTIONNAIRE_RESPONSE_FAIL: `${actionTypePrefix}GET_QUESTIONNAIRE_RESPONSE_FAIL`,

  GET_PSSCIVE_COURSES: `${actionTypePrefix}GET_PSSCIVE_COURSES`,
  GET_PSSCIVE_COURSES_SUCCESS: `${actionTypePrefix}GET_PSSCIVE_COURSES_SUCCESS`,
  GET_PSSCIVE_COURSES_FAIL: `${actionTypePrefix}GET_PSSCIVE_COURSES_FAIL`,

  GET_JOB_PROFILE_BY_USERID: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID`,
  GET_JOB_PROFILE_BY_USERID_SUCCESS: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID_SUCCESS`,
  GET_JOB_PROFILE_BY_USERID_FAIL: `${actionTypePrefix}GET_JOB_PROFILE_BY_USERID_FAIL`,

  GET_FAVORITED_JOB_PROFILE_BY_USERID: `${actionTypePrefix}GET_FAVORITED_JOB_PROFILE_BY_USERID`,
  GET_FAVORITED_JOB_PROFILE_BY_USERID_SUCCESS: `${actionTypePrefix}GET_FAVORITED_JOB_PROFILE_BY_USERID_SUCCESS`,
  GET_FAVORITED_JOB_PROFILE_BY_USERID_FAIL: `${actionTypePrefix}GET_FAVORITED_JOB_PROFILE_BY_USERID_FAIL`,

  GET_ATTRIBUTES_RESPONSES_BY_USERID: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID`,
  GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS`,
  GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL: `${actionTypePrefix}GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL`,

  GET_USER_PREFERENCES_BY_USERID: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID`,
  GET_USER_PREFERENCES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID_SUCCESS`,
  GET_USER_PREFERENCES_BY_USERID_FAIL: `${actionTypePrefix}GET_USER_PREFERENCES_BY_USERID_FAIL`,

  GET_FAVORITE_JOBROLES_BY_USERID: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID`,
  GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS`,
  GET_FAVORITE_JOBROLES_BY_USERID_FAIL: `${actionTypePrefix}GET_FAVORITE_JOBROLES_BY_USERID_FAIL`,

  GET_USER_DETAILS_BY_USERID: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID`,
  GET_USER_DETAILS_BY_USERID_SUCCESS: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID_SUCCESS`,
  GET_USER_DETAILS_BY_USERID_FAIL: `${actionTypePrefix}GET_USER_DETAILS_BY_USERID_FAIL`,

  GET_PASSIONS: `${actionTypePrefix}GET_PASSIONS`,
  GET_PASSIONS_SUCCESS: `${actionTypePrefix}GET_PASSIONS_SUCCESS`,
  GET_PASSIONS_FAIL: `${actionTypePrefix}GET_PASSIONS_FAIL`,

  GET_PASSIONS_BY_USERID: `${actionTypePrefix}GET_PASSIONS_BY_USERID`,
  GET_PASSIONS_BY_USERID_SUCCESS: `${actionTypePrefix}GET_PASSIONS_BY_USERID_SUCCESS`,
  GET_PASSIONS_BY_USERID_FAIL: `${actionTypePrefix}GET_PASSIONS_BY_USERID_FAIL`,

  UPDATE_PASSIONS: `${actionTypePrefix}UPDATE_PASSIONS`,
  UPDATE_PASSIONS_SUCCESS: `${actionTypePrefix}UPDATE_PASSIONS_SUCCESS`,
  UPDATE_PASSIONS_FAIL: `${actionTypePrefix}UPDATE_PASSIONS_FAIL`,

  GET_JOBPROFILES: `${actionTypePrefix}GET_JOBPROFILES`,
  GET_JOBPROFILES_SUCCESS: `${actionTypePrefix}GET_JOBPROFILES_SUCCESS`,
  GET_JOBPROFILES_FAIL: `${actionTypePrefix}GET_JOBPROFILES_FAIL`,

  GET_PASSIONS_JOBPROFILES_BY_USERID: `${actionTypePrefix}GET_PASSIONS_JOBPROFILES_BY_USERID`,
  GET_PASSIONS_JOBPROFILES_BY_USERID_SUCCESS: `${actionTypePrefix}GET_PASSIONS_JOBPROFILES_BY_USERID_SUCCESS`,
  GET_PASSIONS_JOBPROFILES_BY_USERID_FAIL: `${actionTypePrefix}GET_PASSIONS_JOBPROFILES_BY_USERID_FAIL`,

  CLEAR_JOB_ROLES: 'CLEAR_JOB_ROLES',

  GET_MATCHED_JOB_PROFILES: `${actionTypePrefix}GET_MATCHED_JOB_PROFILES`,
  GET_MATCHED_JOB_PROFILES_SUCCESS: `${actionTypePrefix}GET_MATCHED_JOB_PROFILES_SUCCESS`,
  GET_MATCHED_JOB_PROFILES_FAIL: `${actionTypePrefix}GET_MATCHED_JOB_PROFILES_FAIL`,

  GET_NSDC_INSTITUTES: `${actionTypePrefix}GET_NSDC_INSTITUTES`,
  GET_NSDC_INSTITUTES_SUCCESS: `${actionTypePrefix}GET_NSDC_INSTITUTES_SUCCESS`,
  GET_NSDC_INSTITUTES_FAIL: `${actionTypePrefix}GET_NSDC_INSTITUTES_FAIL`,

  GET_NSDC_BATCHES: `${actionTypePrefix}GET_NSDC_BATCHES`,
  GET_NSDC_BATCHES_SUCCESS: `${actionTypePrefix}GET_NSDC_BATCHES_SUCCESS`,
  GET_NSDC_BATCHES_FAIL: `${actionTypePrefix}GET_NSDC_BATCHES_FAIL`,

  GET_NSDC_COURSES: `${actionTypePrefix}GET_NSDC_COURSES`,
  GET_NSDC_COURSES_SUCCESS: `${actionTypePrefix}GET_NSDC_COURSES_SUCCESS`,
  GET_NSDC_COURSES_FAIL: `${actionTypePrefix}GET_NSDC_COURSES_FAIL`,
}

export interface IJobRolesPayload {
  userId?: string;
  res?: any;
  page?: number;
  jobRoleKeys?: string[];
  nonPaginated?: boolean;
  recommendation?: boolean;
}

export interface IFavouritesPayload {
  jobRoleKeys: string[];
}

interface IAttributeScores {
  attributeName: string;
  attributeKey: string;
  userInputScore?: number;
  questionnaireScore?: number;

}

export interface ISubmitUserResponsePayload {
  attributeGroupName: string;
  attributeGroupKey: string;
  attributeScores: IAttributeScores[]
}

export interface IGetQuestionnaireResponse {
  questionnaireId: string;
  respondentId: string;
}

export interface IPssciveCoursePayload {
  searchTerm?: string;
  language?: string;
  searchOn?: string;
  page?: number;
  limit?: number;
}

export interface IJobDetailPayload {
  response: JobProfileModel[];
  isInPassion: boolean;
  isInQuestionnare: boolean;
}

export interface IPassionPayloadModel {
  categoryKey: string;
  passionKeys: string[]
}

export interface IJobProfilePayload {
  [key: string]: JobProfileModel[];
}

export interface IPassionPayloadByUserIdModel {
  passionKeys: string[];
  limit?: number;
  userId: string;
}

export interface INdscInstitutePayload {
  longitude?: string;
  latitude?: string;
  courseIds?: string;
  page?: number;
}


export const fetchJobRoles = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES,
    payload
  }
}

export const fetchJobRolesSuccess = (jobProfiles: JobProfileModel[], total?: number, page?: number) => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES_SUCCESS,
    payload: {
      jobProfiles,
      total,
      page
    }
  }
}

export const fetchJobRolesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOB_ROLES_FAIL,
    payload: error,
  }
}

export const fetchJobDetailById = (jobRoleId: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID,
    payload: jobRoleId
  }
}

export const fetchJobDetailByIdSuccess = (payload: IJobDetailPayload) => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID_SUCCESS,
    payload: payload
  }
}

export const fetchJobDetailByIdFail = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_JOBROLE_BY_ID_FAIL,
    payload: error
  }
}

export const updateCareerPreference = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE,
    payload
  }
}

export const updateCareerPreferenceSuccess = (payload) => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE_SUCCESS,
    payload
  }
}

export const updateCareerPreferenceFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.UPDATE_CAREER_PREFERENCE_FAIL,
    payload: error,
  }
}

export const fetchCareerPreference = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE,
    payload
  }
}

export const fetchCareerPreferenceSuccess = (payload) => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE_SUCCESS,
    payload
  }
}

export const fetchCareerPreferenceFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_CAREER_PREFERENCE_FAIL,
    payload: error,
  }
}

export const addOrRemoveFavourites = (payload: IFavouritesPayload) => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE,
    payload
  }
}

export const addOrRemoveFavouritesSuccess = (jobRoleKeys: string[]) => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_SUCCESS,
    payload: {
      jobRoleKeys
    }
  }
}

export const addOrRemoveFavouritesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.ADD_OR_REMOVE_FAVOURITE_FAIL,
    payload: error,
  }
}

export const getFavouritesKeys = () => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS
  }
}

export const getFavouritesKeysSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS_SUCCESS,
    payload
  }
}

export const getFavouritesKeysFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_FAVOURITE_KEYS_FAIL,
    payload: error,
  }
}

export const fetchAttributeGroups = () => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS
  }
}

export const fetchAttributeGroupsSuccess = (payload) => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_SUCCESS,
    payload
  }
}

export const fetchAttributeGroupsFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.FETCH_ATTRIBUTE_GROUPS_FAIL,
    payload: error,
  }
}

export const submitAttributeUserResponse = (payload: ISubmitUserResponsePayload) => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE,
    payload
  }
}

export const submitAttributeUserResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_SUCCESS,
    payload
  }
}

export const submitAttributeUserResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.SUBMIT_ATTRIBUTE_USER_RESPONSE_FAIL,
    payload: error,
  }
}

export const updateFilterValues = (payload) => {
  return {
    type: careerAdvisorActions.UPDATE_FILTER_VALUES,
    payload: payload,
  };
};

export const deleteAttributeUserResponse = (payload: string) => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE,
    payload
  }
}

export const deleteAttributeUserResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_SUCCESS,
    payload
  }
}

export const deleteAttributeUserResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.DELETE_ATTRIBUTE_USER_RESPONSE_FAIL,
    payload: error,
  }
}

export const getQuestionnaireResponse = (payload: IGetQuestionnaireResponse) => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE,
    payload
  }
}

export const getQuestionnaireResponseSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_SUCCESS,
    payload
  }
}

export const getQuestionnaireResponseFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_QUESTIONNAIRE_RESPONSE_FAIL,
    payload: error,
  }
}

export const getPssciveCourses = (payload: IPssciveCoursePayload) => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES,
    payload
  }
}

export const getPssciveCoursesSuccess = (pssciveCourses: PssciveCourse[], total?: number, page?: number) => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES_SUCCESS,
    payload: {
      pssciveCourses,
      total,
      page
    }
  }
}

export const getPssciveCoursesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_PSSCIVE_COURSES_FAIL,
    payload: error,
  }
}

export const getJobProfileByUserId = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID,
    payload
  }
}

export const getJobProfileByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_SUCCESS,
    payload
  }
}

export const getJobProfileByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_JOB_PROFILE_BY_USERID_FAIL,
    payload: error,
  }
}

export const getFavoritedJobProfileByUserId = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID,
    payload
  }
}

export const getFavoritedJobProfileByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID_SUCCESS,
    payload
  }
}

export const getFavoritedJobProfileByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_FAVORITED_JOB_PROFILE_BY_USERID_FAIL,
    payload: error,
  }
}

export const getAttributesResponsesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID,
    payload
  }
}

export const getAttributesResponsesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_SUCCESS,
    payload
  }
}

export const getAttributesResponsesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_ATTRIBUTES_RESPONSES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getUserPreferencesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID,
    payload
  }
}

export const getUserPreferencesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_SUCCESS,
    payload
  }
}

export const getUserPreferencesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_USER_PREFERENCES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getFavoriteJobrolesByUserId = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID,
    payload
  }
}

export const getFavoriteJobrolesByUserIdSuccess = (payload) => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_SUCCESS,
    payload
  }
}

export const getFavoriteJobrolesByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_FAVORITE_JOBROLES_BY_USERID_FAIL,
    payload: error,
  }
}

export const getUserDetailsByUserId = (payload: String) => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID,
    payload
  }
}

export const getUserDetailsByUserIdSuccess = (payload: string) => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID_SUCCESS,
    payload
  }
}

export const getUserDetailsByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_USER_DETAILS_BY_USERID_FAIL,
    payload: error,
  }
}

export const getPassions = () => {
  return {
    type: careerAdvisorActions.GET_PASSIONS,
  }
}

export const getPassionsSuccess = (payload: IPassionPayloadModel[]) => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_SUCCESS,
    payload
  }
}

export const getPassionsFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_FAIL,
    payload: error,
  }
}

export const getPassionsByUserId = (payload: string) => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_BY_USERID,
    payload
  }
}

export const getPassionsByUserIdSuccess = (payload: IPassionPayloadModel[]) => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_BY_USERID_SUCCESS,
    payload
  }
}

export const getPassionsByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_BY_USERID_FAIL,
    payload: error,
  }
}

export const updatePassions = (payload: IPassionPayloadModel[]) => {
  return {
    type: careerAdvisorActions.UPDATE_PASSIONS,
    payload
  }
}

export const updatePassionsSuccess = (payload: IPassionPayloadModel[]) => {
  return {
    type: careerAdvisorActions.UPDATE_PASSIONS_SUCCESS,
    payload
  }
}

export const updatePassionsFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.UPDATE_PASSIONS_FAIL,
    payload: error,
  }
}

export const getJobProfiles = (payload: IJobRolesPayload) => {
  return {
    type: careerAdvisorActions.GET_JOBPROFILES,
    payload
  }
}

export const getJobProfilesSuccess = (payload: IJobProfilePayload) => {
  return {
    type: careerAdvisorActions.GET_JOBPROFILES_SUCCESS,
    payload
  }
}

export const getJobProfilesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_JOBPROFILES_FAIL,
    payload: error,
  }
}

export const getPassionJobProfileByUserId = (payload: IPassionPayloadByUserIdModel) => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID,
    payload
  }
}

export const getPassionJobProfileByUserIdSuccess = (payload: IJobProfilePayload) => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID_SUCCESS,
    payload
  }
}

export const getPassionJobProfileByUserIdFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_PASSIONS_JOBPROFILES_BY_USERID_FAIL,
    payload: error,
  }
}

export const clearJobRoles = () => ({
  type: careerAdvisorActions.CLEAR_JOB_ROLES
});

export const getMatchedJobProfiles = (payload: IPassionPayloadByUserIdModel) => {
  return {
    type: careerAdvisorActions.GET_MATCHED_JOB_PROFILES,
    payload
  }
}

export const getMatchedJobProfilesSuccess = (payload: IJobProfilePayload) => {
  return {
    type: careerAdvisorActions.GET_MATCHED_JOB_PROFILES_SUCCESS,
    payload
  }
}

export const getMatchedJobProfilesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_MATCHED_JOB_PROFILES_FAIL,
    payload: error,
  }
}

export const getNsdcInstitutes = (payload: INdscInstitutePayload) => {
  return {
    type: careerAdvisorActions.GET_NSDC_INSTITUTES,
    payload
  }
}

export const getNsdcInstitutesSuccess = (institutes: NSDCInstituteModel[], total: number) => {
  return {
    type: careerAdvisorActions.GET_NSDC_INSTITUTES_SUCCESS,
    payload: {
      institutes,
      total,
    }
  }
}

export const getNsdcInstitutesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_NSDC_INSTITUTES_FAIL,
    payload: error,
  }
}

export const getNdscBatches = (payload: any) => {
  return {
    type: careerAdvisorActions.GET_NSDC_BATCHES,
    payload
  }
}

export const getNdscBatchesSuccess = (payload: any) => {
  return {
    type: careerAdvisorActions.GET_NSDC_BATCHES_SUCCESS,
    payload
  }
}

export const getNdscBatchesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_NSDC_BATCHES_FAIL,
    payload: error,
  }
}

export const getNsdcCourses = (payload: IPassionPayloadByUserIdModel) => {
  return {
    type: careerAdvisorActions.GET_NSDC_COURSES,
    payload
  }
}

export const getNsdcCoursesSuccess = (payload: IJobProfilePayload) => {
  return {
    type: careerAdvisorActions.GET_NSDC_COURSES_SUCCESS,
    payload
  }
}

export const getNsdcCoursesFailure = (error: string): IFluxStandardAction<string> => {
  return {
    type: careerAdvisorActions.GET_NSDC_COURSES_FAIL,
    payload: error,
  }
}





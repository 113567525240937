import { JsonProperty } from "json-object-mapper";

interface IMinSpecialization {
  minEduQualID: string;
  minEduQualDesc: string;
  minSpecialization?: string;
}

interface IEduQual {
  eduQual: IMinSpecialization[];
  expReq: Record<string, any>;
}

interface IOccupation {
  occupationID: string;
  occupationDesc: string;
  occupationCode: string;
}

export class NSDCCourseModel {
  @JsonProperty({ name: "_id", required: true })
  private id: string = "";

  @JsonProperty({ name: "uniqueCourseId", required: true })
  private uniqueCourseId: string = "";

  @JsonProperty({ name: "courseId", required: true })
  private courseId: string = "";

  @JsonProperty({ name: "version", required: true })
  private version: string = "";

  @JsonProperty({ name: "courseName", required: true })
  private courseName: string = "";

  @JsonProperty({ name: "courseDescription", required: true })
  private courseDescription: string = "";

  @JsonProperty({ name: "minEduQual", required: true })
  private minEduQual: IEduQual[] = [];

  @JsonProperty({ name: "courseDuration", required: true })
  private courseDuration: string = "";

  @JsonProperty({ name: "occupation", required: true })
  private occupation: IOccupation = {
    occupationID: "",
    occupationDesc: "",
    occupationCode: ""
  };

  @JsonProperty({ name: "deleted", required: true })
  private deleted: boolean = false;

  public getId(): string {
    return this.id;
  }

  public getUniqueCourseId(): string {
    return this.uniqueCourseId;
  }

  public getCourseId(): string {
    return this.courseId;
  }

  public getVersion(): string {
    return this.version;
  }

  public getCourseName(): string {
    return this.courseName;
  }

  public getCourseDescription(): string {
    return this.courseDescription;
  }

  public getMinEduQual(): IEduQual[] {
    return this.minEduQual;
  }

  public getCourseDuration(): string {
    return this.courseDuration;
  }

  public getOccupation(): IOccupation {
    return this.occupation;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }
}

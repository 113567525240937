import { Logger } from "@utils/Logger";

import { AxiosRequestConfig, AxiosResponse } from "axios";
import qs from "qs";

interface IsValidResponse {
    isValid: boolean,
    value?: string,
}

const PREFIX = '**CACHE**';
const SEPARATOR = '//**//';

export const enum APIBaseServices {
    ORGANISATION_SERVICE = 'organizationservice',
    PROFILE_SERVICE = 'profileservice',
    COURSE_SERVICE = 'courseservice',
    TAGS_SERVICE = 'tagsservice',
    SCHEDULING_SERVICE = 'schedulingservice',
    IAM_SERVICE = 'iamservice'
}
// API response structure in cache - **Cache**<api-response>//**//<cache-timestamp>//**//<cache-interval>

const DEFAULT_CACHE_INTERVAL = 10 * 60 * 1000; 
const CACHE_INVALIDATOR_INTERVAL = 0.5 * 60 * 1000;

// White API from Network cache
const whiteList = ['auth'];

let cacheInvalidator: NodeJS.Timer;

export function setNetworkCacheInvalidator() {
    cacheInvalidator = setInterval(function(){
        checkAndInvalidateAPIResponsesInCache()
    },  CACHE_INVALIDATOR_INTERVAL );

}

export function clearNetworkCacheInvalidator() {
    clearInterval(cacheInvalidator);
}

export function checkAndInvalidateAPIResponsesInCache() {

    Logger.log("clearing invalid api responses from cache");

    const sesstionStorageKeys = Object.keys(sessionStorage);
    sesstionStorageKeys.forEach((key: string) => {
        const value = sessionStorage.getItem(key);
        if(value.startsWith(PREFIX)) {
            isValid(key);
        }
    });

}


// Useful when creating a new user or similar, where everything starts from scratch.
// Also useful when we need to clear eveything up
export function resetNetworkLayerCache() {
    Logger.log("Reset NetworkLayer Cache")
    // sessionStorage.clear();
}


function isURLInWhiteList(url: string) {
    return whiteList.includes(url.split('/')[1]);
}


function store(key: string, value: string, cacheInterval: number) {
    const finalValue = `${PREFIX}${value}${SEPARATOR}${Date.now().toString()}${SEPARATOR}${cacheInterval || DEFAULT_CACHE_INTERVAL}`;
    sessionStorage.setItem(key, finalValue);
}


// Returns true if an object has been stored using the store method
// and have yet not expired
function isValid(key: string): IsValidResponse {
    const value = sessionStorage.getItem(key);
    if (value === null) {
        return {
            isValid: false,
        };
    }

    const values = value.split(SEPARATOR);
    const timestamp = Number(values[1]);
    const cacheInterval = Number(values);
    if (Number.isNaN(timestamp)) {
        return {
            isValid: false,
        };
    }

    const date = new Date(timestamp);
    if (date.toString() === 'Invalid Date') {
        return {
            isValid: false,
        };
    }
    Logger.log('cache interval check', Date.now() - date.getTime(), cacheInterval || DEFAULT_CACHE_INTERVAL,(cacheInterval || DEFAULT_CACHE_INTERVAL) - (Date.now() - date.getTime()) )
    if ((Date.now() - date.getTime()) < (cacheInterval || DEFAULT_CACHE_INTERVAL)) {
        const apiResponse = values[0].split(PREFIX)[1];
        return {
            isValid: true,
            value: apiResponse,
        };
    }
    Logger.log('cache item being removed', key);
    sessionStorage.removeItem(key);

    return {
        isValid: false,
    };
}


const getFinalURL = (baseUrl: string, params: any) => {
    let paramString = qs.stringify(params, { arrayFormat: 'comma' });
    return `${baseUrl}${paramString && `?${paramString}`}`;
}

export async function requestCacheHandler(request: AxiosRequestConfig) {
    if (request.method === 'GET' || 'get' && request.headers.toBeCached) {
        const checkIsValidResponse = isValid(getFinalURL(request.url, request.params) || '');
        if (checkIsValidResponse.isValid) {
            Logger.log('serving cached data', getFinalURL(request.url, request.params));
            request.headers.cached = true;
            request.data = JSON.parse(checkIsValidResponse.value || '{}');
            return Promise.reject(request);
        }
    }
    return request;
}

// use this function to clear cache for the particular API base services.
export function clearCacheForService(serviceName: APIBaseServices) {
    const sessionStorageKeys = Object.keys(sessionStorage);
    for (let i = 0; i < sessionStorageKeys.length; i++) {
        if (sessionStorageKeys[i]?.includes(serviceName)) {
            sessionStorage.removeItem(sessionStorageKeys[i]);
        }
    }
}

export function responseCacheHandler(response: AxiosResponse<any>): AxiosResponse<any> {
    if (response.config.method === 'GET' || 'get' && response.config.headers.toBeCached) {
        if (response.config.url && !isURLInWhiteList(response.config.url)) {
            Logger.log('storing in cache', getFinalURL(response.config.url, response.config.params));

            store(getFinalURL(response.config.url, response.config.params), JSON.stringify(response.data), response.config.headers.cacheInterval);
        }
    }
    return response;
}


export function errorCacheHandler(error: any) {
    if (error.headers.cached) {
        Logger.log('got cached data in response, serving it directly', error, error.data);
        return Promise.resolve(error);
    }
}

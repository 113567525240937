import { JobProfileModel } from "@common/domain/models/JobProfileModel";
import { PssciveCourse } from "@common/domain/models/PssciveCourse";
import { IState } from "@store/webInterfaces";

export const getJobRoles = (state: IState): JobProfileModel[] => state?.careerAdvisor?.jobRoles || [] as JobProfileModel[];
export const getJobDetail = (state: IState): JobProfileModel => state?.careerAdvisor?.jobDetail || {} as JobProfileModel;
export const getCareerPreferenceData = (state: IState): String[] => state?.careerAdvisor?.careerPreference || [];
export const getJobTotalCount = (state: IState): Number => state?.careerAdvisor?.totalJobs || 0;
export const getAttributesGroup = (state: IState): any => state?.careerAdvisor?.attributesGroup;
export const getAttributesGroupLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.attributesGroup;
export const getJobRolesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.fetchJobRoles || false;
export const getJobRolesById = (state: IState): any => state?.careerAdvisor?.jobRolesById || {};
export const getJobDetailLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.fetchJobDetail || false;
export const updateCareerPreferenceLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.updateCareerPreference || false;
export const getCareerPreferenceLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.fetchCareerPreference || false;
export const getJobRolesError = (state: IState): string => state?.careerAdvisor?.errors?.fetchJobRoles || "";
export const getJobDetailError = (state: IState):string => state?.careerAdvisor?.errors?.fetchJobDetail || "";
export const updateCareerPreferenceError = (state: IState):string => state?.careerAdvisor?.errors?.updateCareerPreference || "";
export const getCareerPreferenceError = (state: IState):string => state?.careerAdvisor?.errors?.fetchCareerPreference || "";
export const getAttributesGroupByKey = (state: IState): any => state?.careerAdvisor?.attributesGroupByKey;
export const getAttributeUserResponseLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.submitAttributeUserResponse || false;
export const getFilterValues = (state: IState): any => state?.careerAdvisor?.filterValues;
export const getFavouriteKeys = (state: IState): string[] => state?.careerAdvisor?.getFavouriteKeys || [];
export const getAddOrRemoveFavourites = (state:IState):boolean=> state?.careerAdvisor?.loaders?.addOrRemoveFavourites || false;
export const deleteAttributeUserResponse = (state: IState): any => state?.careerAdvisor?.deleteAttributeUserResponse || {};
export const deleteAttributeUserResponseLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.deleteAttributeUserResponse || false;
export const getQuestionnaireResponseData = (state: IState): any => state?.careerAdvisor?.getQuestionnaireResponse || [];
export const getQuestionnaireResponseDataLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getQuestionnaireResponse || false;
export const pssciveCourses = (state: IState): PssciveCourse[] => state?.careerAdvisor?.pssciveCourses || [];
export const pssciveCoursesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.pssciveCourses || false;
export const pssciveCoursesCount = (state: IState): number => state?.careerAdvisor?.pssciveCoursesCount || 0;
export const getJobProfileByUserIdData = (state: IState): any => state?.careerAdvisor?.getJobProfileByUserId || [];
export const getJobProfileByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getJobProfileByUserId || false;
export const getJobProfileByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getJobProfileByUserId || "";
export const getAttributesResponseByUserIdData = (state: IState): any => state?.careerAdvisor?.getAttributesResponseByUserId || [];
export const getAttributesResponseByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getAttributesResponseByUserId || false;
export const getAttributesResponseByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getAttributesResponseByUserId || "";
export const getUserPreferenceByUserIdData = (state: IState): any => state?.careerAdvisor?.getUserPreferenceByUserId || [];
export const getUserPreferenceByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getUserPreferenceByUserId || false;
export const getUserPreferenceByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getUserPreferenceByUserId || "";
export const getFavoriteJobRolesByUserIdData = (state: IState): any => state?.careerAdvisor?.getFavoriteJobRolesByUserId || [];
export const getFavoriteJobRolesByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getFavoriteJobRolesByUserId || false;
export const getFavoriteJobRolesByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getFavoriteJobRolesByUserId || "";
export const getFavoriteJobProfileByUserIdData = (state: IState): any => state?.careerAdvisor?.getFavoriteJobProfileByUserId || [];
export const getUserDetailsByUserIdData = (state: IState): any => state?.careerAdvisor?.getUserDetailsByUserId || [];
export const getUserDetailsByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getUserDetailsByUserId || false;
export const getUserDetailsByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getUserDetailsByUserId || "";

export const getPassionsData = (state: IState): any => state?.careerAdvisor?.getPassions || null;
export const getPassionsLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getPassions || false;
export const getPassionsError = (state: IState): string => state?.careerAdvisor?.errors?.getPassions || "";

export const getPassionsByUserIdData = (state: IState): any => state?.careerAdvisor?.getPassionsByUserId || null;
export const getPassionsByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.getPassionsByUserId || false;
export const getPassionsByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.getPassionsByUserId || "";

export const getJobProfilesData = (state: IState): any => state?.careerAdvisor?.jobProfiles || [];
export const getJobProfilesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.jobProfiles || false;
export const getJobProfilesError = (state: IState): string => state?.careerAdvisor?.errors?.jobProfiles || "";

export const getPassionsJobProfilesByUserIdData = (state: IState): any => state?.careerAdvisor?.passionJobProfilesByUserId || [];
export const getPassionsJobProfilesByUserIdLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.passionJobProfilesByUserId || false;
export const getPassionsJobProfilesByUserIdError = (state: IState): string => state?.careerAdvisor?.errors?.passionJobProfilesByUserId || "";

export const getMatchedJobProfileData = (state: IState): any => state?.careerAdvisor?.matchedJobProfile || [];
export const getMatchedJobProfileLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.matchedJobProfile || false;
export const getMatchedJobProfileError = (state: IState): string => state?.careerAdvisor?.errors?.matchedJobProfile || "";

export const getNsdcInstitutesData = (state: IState): any => state?.careerAdvisor?.nsdcInstitutes || [];
export const getNsdcInstitutesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.nsdcInstitutes || false;
export const getNsdcInstitutesError = (state: IState): string => state?.careerAdvisor?.errors?.nsdcInstitutes || "";
export const getNsdcInstitutesTotal = (state: IState): any => state?.careerAdvisor?.nsdcInstitutesTotal || [];

export const getNsdcCoursesData = (state: IState): any => state?.careerAdvisor?.nsdcCourses || [];
export const getNsdcCoursesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.nsdcCourses || false;
export const getNsdcCoursesError = (state: IState): string => state?.careerAdvisor?.errors?.nsdcCourses || "";

export const getNsdcBatchesData = (state: IState): any => state?.careerAdvisor?.nsdcBatches || [];
export const getNsdcBatchesLoader = (state: IState): boolean => state?.careerAdvisor?.loaders?.nsdcBatches || false;
export const getNsdcBatchesError = (state: IState): string => state?.careerAdvisor?.errors?.nsdcBatches || "";


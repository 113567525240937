import { i18n } from "@translations/i18n";
import { attributeStatus, ratingLevel } from "./constant";
import { attributeCompleteStatus, attributeProgressStatus } from "@assets/index";
import { cExclaim } from "@assets/images";
import { genderType } from "@constants/config";
import axios from "axios";

export const getAttributeFilterResponse = (groupResponse, groupMetaData) => {
  groupMetaData?.sort((a, b) => {
    if (a?.key < b?.key) return -1;
    if (a?.key > b?.key) return 1;
    return 0;
  });
  groupMetaData?.forEach(eachMetaData => {
    const matchingGroup = groupResponse?.find(eachResponse => eachResponse?.attributeGroupKey === eachMetaData?.key);
    if (matchingGroup) {
      eachMetaData?.attributes?.forEach(attribute => {
        const matchingScore = matchingGroup?.attributeScores?.find(score => score?.attributeKey === attribute?.attributeKey);
        if (matchingScore) {
          attribute.questionnaireScore = matchingScore?.questionnaireScore;
          attribute.userInputScore = matchingScore?.userInputScore ? matchingScore?.userInputScore : matchingScore?.questionnaireScore;
        }
      });
    }
  });
  return groupMetaData;
};

export const getAttributeSliderType = (score) => {
  if (score >= 8) {
    return 2;
  } else if (score > 3 && score < 8) {
    return 1;
  } else {
    return 0;
  }
};

export const extractAttributeDifferentValues = (defaultAttributes = [], changedAttributes = []) => {
  const arrayDiffer = [];
  defaultAttributes.forEach(eachDefaultAttribute => {
    changedAttributes.forEach(eachChangedAttribute => {
      const isValueExists = arrayDiffer.findIndex(eachArrayDiffer => eachArrayDiffer?.attributeKey === eachChangedAttribute?.attributeKey);
      const isScoreNotExists = eachDefaultAttribute?.attributeKey === eachChangedAttribute?.attributeKey && eachDefaultAttribute?.userInputScore !== eachChangedAttribute?.userInputScore;
      if (isScoreNotExists && isValueExists === -1) {
        arrayDiffer.push(eachChangedAttribute);
      }
    });
  });
  return arrayDiffer;
};

export const extractAttributePayload = (groupKey, groupValues, isQuestionnaireRating = false) => {
  return {
    attributeGroupName: i18n.t(`attributeGroups.${groupKey}.name`),
    attributeGroupKey: groupKey,
    attributeScores: groupValues.map(eachGroupValue => {
      return {
        attributeName: i18n.t(`attributes.${eachGroupValue?.attributeKey}.title`),
        attributeKey: eachGroupValue?.attributeKey,
        userInputScore: eachGroupValue?.userInputScore,
        questionnaireScore: isQuestionnaireRating ? eachGroupValue?.userInputScore : eachGroupValue?.questionnaireScore ? eachGroupValue?.questionnaireScore : eachGroupValue?.userInputScore
      }
    })
  }
};

export const attributeCompletionStatus = (attributes, isRetake = false) => {
  if (attributes.length > 0) {
    const isAllAttributesCompleted = attributes.every(eachAttribute => eachAttribute?.userInputScore && eachAttribute?.userInputScore > 0);
    const isSomeAttributesCompleted = attributes.some(eachAttribute => eachAttribute?.userInputScore && eachAttribute?.userInputScore > 0);
    if (isAllAttributesCompleted) {
      return {
        type: isRetake ? attributeStatus.RETAKE : attributeStatus.REVIEW,
        value: i18n.t(`careerAdvisor.labelsAndTitles.${isRetake ? 'RETAKE' : 'REVIEW'}`)
      }
    } else if (isSomeAttributesCompleted) {
      return {
        type: attributeStatus.RESUME,
        value: i18n.t(`general.resume`)
      }
    } else {
      return {
        type: attributeStatus.START,
        value: i18n.t(`general.start`)
      }
    }
  }
  return {
    type: attributeStatus.START,
    value: i18n.t(`general.start`)
  }

};

export const getNextAttributeKey = (attributes) => {
  const nextAttributeKey = attributes.find(eachAttribute => !eachAttribute?.userInputScore);
  return nextAttributeKey ? nextAttributeKey?.attributeKey : attributes[0]?.attributeKey;
};

export const getLastScoredAttribute = (attributes) => {
  const scoredAttributes = attributes.filter(attribute => attribute?.userInputScore);
  if (scoredAttributes.length === 0) {
    return null;
  }
  const lastScoredAttribute = scoredAttributes[scoredAttributes.length - 1];
  return lastScoredAttribute;
};


export const getOverAllAttributePercentage = (attributes = []) => {
  const completedAttributes = attributes.filter(eachAttribute => eachAttribute?.userInputScore);
  return (completedAttributes.length / attributes.length) * 100;
};

export const extractTopAttributes = (attributes = [], count = 3, isJobDetail = false) => {
  const extractAttributes = [...attributes];
  let sortedArray = [];
  if (isJobDetail) {
    sortedArray = extractAttributes.sort((a, b) => parseInt(b?.score) - parseInt(a?.score));
  } else {
    sortedArray = extractAttributes.sort((a, b) => b?.userInputScore - a?.userInputScore);
  }
  const topElements = sortedArray.slice(0, count);
  if (isJobDetail) {
    return topElements;
  };
  const remainingMatingElements = sortedArray.slice(count, sortedArray.length).filter(remainingElement => remainingElement?.userInputScore >= topElements[topElements.length - 1]?.userInputScore) || [];
  const top4Keys = [...topElements, ...remainingMatingElements].map(item => item?.attributeKey);
  return top4Keys;
};

export const getNotCompletedQuestionnaireGroups = (attributesGroup) => {
  const notCompletedGroups = [];
  attributesGroup.forEach(eachAttributeGroup => {
    const isAllAttributesCompleted = eachAttributeGroup?.attributes?.every(eachAttribute => eachAttribute?.userInputScore && eachAttribute?.userInputScore > 0);
    if (!isAllAttributesCompleted) {
      notCompletedGroups.push(eachAttributeGroup);
    }
  });
  return notCompletedGroups;
};

export const getStatusImage = (attributeGroup) => {
  const type = attributeCompletionStatus(attributeGroup?.getAttributes())?.type;
  if (type === attributeStatus.REVIEW) {
    return attributeCompleteStatus;
  } else if (type === attributeStatus.RESUME) {
    return attributeProgressStatus;
  } else {
    return cExclaim;
  }
};

export const getStatusImagePassion = (data) => {
  if (data !== null) return attributeCompleteStatus
  else return cExclaim
}

export const getFilteredFavourites = (favouriteKeys, jobRoleKey) => {
  if (favouriteKeys.includes(jobRoleKey)) {
    return favouriteKeys.filter(eachFavourite => eachFavourite !== jobRoleKey);
  } else {
    favouriteKeys.unshift(jobRoleKey);
  }
  return favouriteKeys;
};

export const isQuestionnareCompleted = (data = []) => {
  if (data.length === 0) {
    return false
  }
  for (const item of data) {
    if (item.attributes && Array.isArray(item.attributes)) {
      for (const attribute of item.attributes) {
        if (!attribute.userInputScore) {
          return false;
        }
      }
    }
  }
  return true;
};

export const showEnterprenuerCard = (data = []) => {
  let attributeScore = data?.find(el => el.key === 'interest')?.attributes?.find(att => att?.attributeKey === "enterprising")?.userInputScore || 0;
  return attributeScore > 7 ? true : false;
};

export const isAttributesValueExists = (attributes = []) => {
  return attributes.length > 0 && attributes.every(eachAttribute => eachAttribute?.userInputScore > 0);
};

export const isAttributesSomeValueExists = (attributes = []) => {
  return attributes.length > 0 && attributes.some(eachAttribute => eachAttribute?.userInputScore > 0);
};

export const isAttributesQuestionValueZero = (attributes = []) => {
  return attributes.length > 0 && attributes.every(eachAttribute => !eachAttribute?.questionnaireScore);
};

export const isAttributeKeyValueExists = (attributeGroup, factorDetails) => {
  if (isQuestionnareCompleted(attributeGroup)) {
    let ratingType;
    attributeGroup.forEach(eachAttributeGroup => {
      eachAttributeGroup.attributes.forEach(eachAttributeGroup => {
        if (eachAttributeGroup.attributeKey === factorDetails.attributeKey) {
          if (eachAttributeGroup.userInputScore > parseInt(factorDetails.score)) {
            ratingType = ratingLevel.HIGH;
          } else if (eachAttributeGroup.userInputScore === parseInt(factorDetails.score)) {
            ratingType = ratingLevel.EQUAL;
          } else {
            ratingType = ratingLevel.LOW;
          }
        }
      });
    });
    return ratingType;
  }
  return '';
};

export const assignGenderFromId = (objectId: string) => {
  if (objectId) {
    const lastChar = objectId.slice(-1);
    const numericValue = parseInt(lastChar, 16);
    if (isNaN(numericValue)) {
      throw new Error("Invalid MongoDB ObjectID.");
    }
    return numericValue % 2 === 0 ? genderType.male : genderType.female;
  }
}

export const getSource = (pathname: string) => {
  if (pathname.includes('matched-roles'))
    return 'recomm';
  else if (pathname.includes('browse-roles'))
    return 'browse';
  else return 'voca';
}

export const removeBracketValues = (text) => {
  return text.replace(/\[.*?\]|\(.*?\)/g, "").trim();
}


export const fetchLocationByIP = async (url) => {
  try {
    const response = await axios.get(url);
    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export const getTcIDs = (data) => {
  let TcIds = [];
  data?.forEach(batch => {
    TcIds.push(...(batch?.jobRoles?.map(job => job?.qpCode)));
  });
  return TcIds?.join('||');
};

export const getFilterResponse = (batchResponse, courseResponse) => {
  if (!batchResponse || !courseResponse) {
    return [];
  }

  const filterData = []
  batchResponse.forEach(batch => {
    if (batch?.jobRoles) {
      batch.jobRoles.forEach(job => {
        const matchingItem = courseResponse.find(course => course?.courseId === job?.qpCode);
        if (matchingItem) {
          filterData.push({
            batchStartDate: batch.batchStartDate,
            batchEndDate: batch.batchEndDate,
            courseName: matchingItem.courseName,
            courseDescription: matchingItem.courseDescription,
            courseId: matchingItem.courseId,
          })
        }
      })
    }
  })
  return filterData;
}

export const isBetweenTheDates = (startDate, endDate) => {
  const currentDate = new Date();
  return currentDate >= startDate && currentDate <= endDate;
}

export const getDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);

    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${day}${suffix(day)} ${month} '${year}`;
  };

  return `${formatDate(start)} - ${formatDate(end)}`;
}

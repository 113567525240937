
import { ProfileModel } from "@common/domain/models/Profile";
import { DataUtility } from '@utils/DataUtility';

export interface IIncompleteField {
  fieldName: string;
  message: string;
}

export type FieldVisibilityMap = { [fieldName: string]: boolean };

export const validateFields = (requiredField, validObj) => {
  let isValidated = true;
  let invalidKeys = {};
  for (let i = 0; i < requiredField.length; i++) {
    if (DataUtility.isEmpty(validObj?.[requiredField[i]])) {
      isValidated = false;
      invalidKeys[requiredField[i]] = true;
    }
  }
  return { isValidated, invalidKeys };
}

export const USER_FIELD_NAMES = Object.freeze({
  profilePic: "profilePic",
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  language: "language",
  dob: "dob",
  gender: "gender",
  phone: "phone",
  linkedIn: "linkedIn",
  facebook: "facebook",
  twitter: "twitter",
  bio: "bio",
  location: "location",
  summary: "summary",
  workExperience: "workExperience",
  spokenLanguage: "spokenLanguage"
});

export const profileMetaDataKey = {
  PROGRAMS: "programs",
  PROFILE_LANGUAGES: "profile-languages",
  EXPERT_SECTOR: "expert-sectors",
  EXPERT_JOB_ROLE: "expert-job-roles",
  WORK_EXPERIENCE: "workexperience-filter"
}

type ProfileField = {
  fieldName: string;
  fieldData: any;
  message: string;
}

export const initialAreFieldsEditted = {
  firstName: false,
  lastName: false,
  gender: false,
  organisationId: false,
  city: false,
  bio: false,
  linkedIn: false,
  education: false,
  yearOfBirth: false,
  calcomUrl: false,
  language: false,
  spokenLanguage: false,
  workExperience: false,
  expertType: false,
  sector: false,
  jobRole: false,
  employmentDetails: false,
  heading: false,
  facebook: false,
  twitter: false,
  email: false,
  phone: false,
}

const requiredCommonFields = (profile: ProfileModel): ProfileField[] => {
  let requiredFields: ProfileField[] = [
    {
      fieldName: USER_FIELD_NAMES.firstName,
      fieldData: profile.getFirstName(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.lastName,
      fieldData: profile.getLastname(),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.location,
      fieldData: profile?.getLocation && profile?.getLocation() && (profile?.getLocation()?.city || profile?.getLocation()?.country),
      message: '',
    },
    {
      fieldName: USER_FIELD_NAMES.language,
      fieldData: profile.getPreferredLanguage(),
      message: '',
    },
  ]
  return requiredFields;
}

export const flattenGroupValues = (groupedArr) => {
  const result = [];
  groupedArr?.forEach(group => {
    group.groupValues.forEach(value => {
      result.push({ groupName: group.groupName, value: value });
    });
  });

  return result;
}

export const getRequiredIncompleteFields = (
  profile: ProfileModel
): {
  incompleteFields: IIncompleteField[];
  visibilityMap: FieldVisibilityMap;
} => {
  const incompleteFields: IIncompleteField[] = [];
  const visibilityMap: FieldVisibilityMap = {};
  const requiredFields = requiredCommonFields(profile);

  requiredFields.forEach((field: ProfileField) => {
    if (DataUtility.isEmpty(field.fieldData)) {
      incompleteFields.push({
        fieldName: field.fieldName,
        message: field.message,
      });
      visibilityMap[field.fieldName] = true;
    }

  })

  return { incompleteFields, visibilityMap };
};

export const POSTREGISTRATION_SCREENS = {
  BASICDETAILS: "BasicDetails",
  PLACEMENTDETAILS: "PlacementData"
}

export const PLACEMENT_STATUS = {
  UNEMPLOYED: "UNEMPLOYED",
  STUDENT_EMPLOYED: "STUDENT_EMPLOYED",
  STUDENT: "STUDENT",
  EMPLOYED: "EMPLOYED"
}

export const convertJobRoletoPayloadFormat = (selectedItems) => {
  // Initialize a map to group values by groupName
  const groupedMap = new Map();

  selectedItems.forEach(item => {
    const { groupName, value } = item;
    if (!groupedMap.has(groupName)) {
      groupedMap.set(groupName, []);
    }
    groupedMap.get(groupName).push(value);
  });

  // Convert the map back to the desired array format
  const result = [];
  groupedMap.forEach((groupValues, groupName) => {
    result.push({ groupName, groupValues });
  });

  return result;
}

import { JsonProperty } from "json-object-mapper";

interface IStateDistrict {
  id: number;
  name: string;
}

interface ILocation {
  latitude: string;
  longitude: string;
}

interface IAddress {
  addressLine: string;
  landmark: string;
  pincode: string;
  zone: string;
  state: IStateDistrict;
  district: IStateDistrict;
  location: ILocation;
}

interface IBatchTiming {
  start: string;
  end: string;
}

interface ISector {
  id: string;
  name: string;
}

interface IJobRole {
  jobName: string;
  qpCode: string;
  nsqfLevel: string;
  assessmentStartDate: string;
  assessmentEndDate: string;
  sector: ISector;
  personalAttrs: string;
  jobRoleDesc: string;
  courseName: string;
  courseId: string;
}

interface ITrainerDetails {
  name: string;
  approvalDetails: any;
}

interface ITcSpoc {
  email: string;
  mobileNumber: number;
}

export class NSDCBatchModel {
  @JsonProperty({ name: "_id", required: true })
  private id: string = "";

  @JsonProperty({ name: "batchId", required: true })
  private batchId: number = 0;

  @JsonProperty({ name: "batchName", required: true })
  private batchName: string = "";

  @JsonProperty({ name: "batchStartDate", required: true })
  private batchStartDate: string = "";

  @JsonProperty({ name: "batchEndDate", required: true })
  private batchEndDate: string = "";

  @JsonProperty({ name: "jobRoles", required: true })
  private jobRoles: IJobRole[] = [];

  @JsonProperty({ name: "address", required: true })
  private address: IAddress = {
    addressLine: "",
    landmark: "",
    pincode: "",
    zone: "",
    state: {
      id: 0,
      name: ""
    },
    district: {
      id: 0,
      name: ""
    },
    location: {
      latitude: "",
      longitude: ""
    }
  };

  @JsonProperty({ name: "schemeId", required: true })
  private schemeId: string = "";

  @JsonProperty({ name: "schemeName", required: true })
  private schemeName: string = "";

  @JsonProperty({ name: "subSchemeName", required: true })
  private subSchemeName: string = "";

  @JsonProperty({ name: "batchTimings", required: true })
  private batchTimings: IBatchTiming[] = [];

  @JsonProperty({ name: "batchDurationInDays", required: true })
  private batchDurationInDays: number = 0;

  @JsonProperty({ name: "tcId", required: true })
  private tcId: string = "";

  @JsonProperty({ name: "tcName", required: true })
  private tcName: string = "";

  @JsonProperty({ name: "tpId", required: true })
  private tpId: string = "";

  @JsonProperty({ name: "tpName", required: true })
  private tpName: string = "";

  @JsonProperty({ name: "trainingType", required: true })
  private trainingType: string = "";

  @JsonProperty({ name: "type", required: true })
  private type: string = "";

  @JsonProperty({ name: "batchType", required: true })
  private batchType: string = "";

  @JsonProperty({ name: "isCSSMBatch", required: false })
  private isCSSMBatch: string = "";

  @JsonProperty({ name: "trainerDetails", required: true })
  private trainerDetails: ITrainerDetails = {
    name: "",
    approvalDetails: {}
  };

  @JsonProperty({ name: "size", required: true })
  private size: number = 0;

  @JsonProperty({ name: "trackBatch", required: true })
  private trackBatch: string = "";

  @JsonProperty({ name: "updatedOn", required: true })
  private updatedOn: string = "";

  @JsonProperty({ name: "createdOn", required: true })
  private createdOn: string = "";

  @JsonProperty({ name: "tcSpoc", required: true })
  private tcSpoc: ITcSpoc = {
    email: "",
    mobileNumber: 0
  };

  @JsonProperty({ name: "deleted", required: true })
  private deleted: boolean = false;

  public getId(): string {
    return this.id;
  }

  public getBatchId(): number {
    return this.batchId;
  }

  public getBatchName(): string {
    return this.batchName;
  }

  public getBatchStartDate(): string {
    return this.batchStartDate;
  }

  public getBatchEndDate(): string {
    return this.batchEndDate;
  }

  public getJobRoles(): IJobRole[] {
    return this.jobRoles;
  }

  public getAddress(): IAddress {
    return this.address;
  }

  public getSchemeId(): string {
    return this.schemeId;
  }

  public getSchemeName(): string {
    return this.schemeName;
  }

  public getSubSchemeName(): string {
    return this.subSchemeName;
  }

  public getBatchTimings(): IBatchTiming[] {
    return this.batchTimings;
  }

  public getBatchDurationInDays(): number {
    return this.batchDurationInDays;
  }

  public getTcId(): string {
    return this.tcId;
  }

  public getTcName(): string {
    return this.tcName;
  }

  public getTpId(): string {
    return this.tpId;
  }

  public getTpName(): string {
    return this.tpName;
  }

  public getTrainingType(): string {
    return this.trainingType;
  }

  public getType(): string {
    return this.type;
  }

  public getBatchType(): string {
    return this.batchType;
  }

  public getIsCSSMBatch(): string {
    return this.isCSSMBatch;
  }

  public getTrainerDetails(): ITrainerDetails {
    return this.trainerDetails;
  }

  public getSize(): number {
    return this.size;
  }

  public getTrackBatch(): string {
    return this.trackBatch;
  }

  public getUpdatedOn(): string {
    return this.updatedOn;
  }

  public getCreatedOn(): string {
    return this.createdOn;
  }

  public getTcSpoc(): ITcSpoc {
    return this.tcSpoc;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }
}

import { JsonProperty } from 'json-object-mapper';

export interface ISchoolLocation {
  city: string;
  district: string;
  admin_name: string;
  country: string;
}

export class SchoolModel {
  @JsonProperty({name: '_id', required: true})
  private _id: string = '';

  @JsonProperty({name: 'location', required: true})
  private location: ISchoolLocation = {
    city: '',
    district: '',
    admin_name: '',
    country: ''
  };

  @JsonProperty({name: 'UDISEID', required: false})
  private UDISEID: string | null = null;

  @JsonProperty({name: 'name', required: true})
  private name: string = '';

  @JsonProperty({name: 'deleted', required: false})
  private deleted: boolean = false;

  @JsonProperty({name: 'createdAt', required: false})
  private createdAt: string = '';

  @JsonProperty({name: 'updatedAt', required: false})
  private updatedAt: string = '';

  @JsonProperty({name: 'source', required: false})
  private source: string = '';

  @JsonProperty({name: 'priority', required: false})
  private priority: number = 0;

  public getId = (): string => {
    return this._id;
  }

  public getLocation = (): ISchoolLocation => {
    return this.location;
  }

  public getUDISEID = (): string | null => {
    return this.UDISEID;
  }

  public getName = (): string => {
    return this.name;
  }

  public isDeleted = (): boolean => {
    return this.deleted;
  }

  public getCreatedAt = (): string => {
    return this.createdAt;
  }

  public getUpdatedAt = (): string => {
    return this.updatedAt;
  }

  public getSource = (): string => {
    return this.source;
  }

  public getPriority = (): number => {
    return this.priority;
  }

  public getFullLocation = (): string => {
    return `${this.location.district}, ${this.location.admin_name}`;
  }
}

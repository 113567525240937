import { JsonProperty } from "json-object-mapper";

interface ITrainingPartner {
  name: string;
  userName: string;
  type: string;
}

interface IStateDistrict {
  id: number;
  name: string;
}

interface ISubDistrict {
  id: string;
  name: string;
}

interface ILocation {
  latitude: string;
  longitude: string;
}

interface IGeoJSONLocation {
  type: string;
  coordinates: number[];
}

interface IAddress {
  addressLine: string;
  landmark: string;
  pincode: string;
  state: IStateDistrict;
  district: IStateDistrict;
  subDistrict: ISubDistrict;
  location: ILocation;
  geoJSONLocation: IGeoJSONLocation;
}

export class NSDCInstituteModel {
  @JsonProperty({ name: "_id", required: true })
  private id: string = "";

  @JsonProperty({ name: "trainingCentreId", required: true })
  private trainingCentreId: string = "";

  @JsonProperty({ name: "trainingCentreName", required: true })
  private trainingCentreName: string = "";

  @JsonProperty({ name: "trainingPartner", required: true })
  private trainingPartner: ITrainingPartner = {
    name: "",
    userName: "",
    type: ""
  };

  @JsonProperty({ name: "trainingCenterType", required: true })
  private trainingCenterType: string = "";

  @JsonProperty({ name: "centerTypeName", required: true })
  private centerTypeName: string = "";

  @JsonProperty({ name: "address", required: true })
  private address: IAddress = {
    addressLine: "",
    landmark: "",
    pincode: "",
    state: {
      id: 0,
      name: ""
    },
    district: {
      id: 0,
      name: ""
    },
    subDistrict: {
      id: "",
      name: ""
    },
    location: {
      latitude: "",
      longitude: ""
    },
    geoJSONLocation: {
      type: "",
      coordinates: []
    }
  };

  @JsonProperty({ name: "createdOn", required: true })
  private createdOn: string = "";

  @JsonProperty({ name: "deleted", required: true })
  private deleted: boolean = false;

  @JsonProperty({ name: "email", required: false })
  private email: string = '';

  public getEmail(): string {
    return this.email;
  }

  public getId(): string {
    return this.id;
  }

  public getTrainingCentreId(): string {
    return this.trainingCentreId;
  }

  public getTrainingCentreName(): string {
    return this.trainingCentreName;
  }

  public getTrainingPartner(): ITrainingPartner {
    return this.trainingPartner;
  }

  public getTrainingCenterType(): string {
    return this.trainingCenterType;
  }

  public getCenterTypeName(): string {
    return this.centerTypeName;
  }

  public getAddress(): IAddress {
    return this.address;
  }

  public getCreatedOn(): string {
    return this.createdOn;
  }

  public getDeleted(): boolean {
    return this.deleted;
  }
}
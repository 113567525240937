import message from "antd/lib/message"
import { ReactNode } from "react";

export const configureDisplayMessage = (config: any = {
    top: 50,
    duration: 3,
    maxCount: 1,
}) => {
    message.config(config);
};

export const displayInfoMessage = (content: string | ReactNode, duration?: number, onClose?: () => void) => {
    message.info(content, duration, onClose);
}

export const displaySuccessMessage = (content: string | ReactNode, duration?: number, onClose?: () => void) => {
    message.success(content, duration, onClose);
}

export const displayErrorMessage = (content: string | ReactNode, duration?: number, onClose?: () => void) => {
    message.error(content, duration, onClose);
}

export const displayWarningMessage = (content: string | ReactNode, duration?: number, onClose?: () => void) => {
    message.warning(content, duration, onClose);
}

export const displayLoadingMessage = (content: string | ReactNode, duration?: number, onClose?: () => void) => {
    message.loading(content, duration, onClose);
}

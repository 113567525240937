import { ICreateProfilePayload } from "@redux/auth/interface";

import { IDepartment, IMapUserInvitationPayload, IMapUserInvitationPayloadAction, IProfile, IUserEducationQualification, ProfileModel } from "@models/Profile";
import { IFluxStandardAction } from "@store/webInterfaces";

const actionTypePrefix = "PROFILE/";

export const profileActions = {
  FETCH_PROFILES_BY_USER_IDS: `${actionTypePrefix}FETCH_PROFILES_BY_USER_IDS`,
  FETCH_PROFILES_BY_USER_IDS_SUCCESS: `${actionTypePrefix}FETCH_PROFILES_BY_USER_IDS_SUCCESS`,
  FETCH_PROFILES_BY_USER_IDS_FAIL: `${actionTypePrefix}FETCH_PROFILES_BY_USER_IDS_FAIL`,

  CREATE_PROFILE: `${actionTypePrefix}CREATE_PROFILE`,
  CREATE_PROFILE_SUCCESS: `${actionTypePrefix}CREATE_PROFILE_SUCCESS`,
  CREATE_PROFILE_FAIL: `${actionTypePrefix}CREATE_PROFILE_FAIL`,

  FETCH_PROFILE_DETAILS: `${actionTypePrefix}FETCH_PROFILE_DETAILS`,
  FETCH_PROFILE_DETAILS_SUCCESS: `${actionTypePrefix}FETCH_PROFILE_DETAILS_SUCCESS`,
  FETCH_PROFILE_DETAILS_FAIL: `${actionTypePrefix}FETCH_PROFILE_DETAILS_FAIL`,

  UPDATE_PROFILE_DETAILS: `${actionTypePrefix}UPDATE_PROFILE_DETAILS`,
  UPDATE_PROFILE_DETAILS_SUCCESS: `${actionTypePrefix}UPDATE_PROFILE_DETAILS_SUCCESS`,
  UPDATE_PROFILE_DETAILS_FAIL: `${actionTypePrefix}UPDATE_PROFILE_DETAILS_FAIL`,

  FETCH_EDUCATION_QUALIFICATION: `${actionTypePrefix}FETCH_EDUCATION_QUALIFICATION`,
  FETCH_EDUCATION_QUALIFICATION_SUCCESS: `${actionTypePrefix}FETCH_EDUCATION_QUALIFICATION_SUCCESS`,
  FETCH_EDUCATION_QUALIFICATION_FAIL: `${actionTypePrefix}FETCH_EDUCATION_QUALIFICATION_FAIL`,

  FETCH_PROFILES_BY_EMAIL_ID: `${actionTypePrefix}FETCH_PROFILES_BY_EMAIL_ID`,
  FETCH_PROFILES_BY_EMAIL_ID_SUCCESS: `${actionTypePrefix}FETCH_PROFILES_BY_EMAIL_ID_SUCCESS`,
  FETCH_PROFILES_BY_EMAIL_ID_FAIL: `${actionTypePrefix}FETCH_PROFILES_BY_EMAIL_ID_FAIL`,

  FETCH_DEPARTMENTS: `${actionTypePrefix}FETCH_DEPARTMENTS`,
  FETCH_DEPARTMENTS_SUCCESS: `${actionTypePrefix}FETCH_DEPARTMENTS_SUCCESS`,
  FETCH_DEPARTMENTS_FAIL: `${actionTypePrefix}FETCH_DEPARTMENTS_FAIL`,
  SHOW_PROFILE_MODAL: `${actionTypePrefix}SHOW_PROFILE_MODAL`,

  MAP_USER_INVITATION: `${actionTypePrefix}MAP_USER_INVITATION`,
  MAP_USER_INVITATION_SUCCESS: `${actionTypePrefix}MAP_USER_INVITATION_SUCCESS`,
  MAP_USER_INVITATION_FAIL: `${actionTypePrefix}MAP_USER_INVITATION_FAIL`,

  FETCH_PRODUCT_METADATA_START: `${actionTypePrefix}FETCH_PRODUCT_METADATA_START`,
  FETCH_PRODUCT_METADATA_SUCCESS: `${actionTypePrefix}FETCH_PRODUCT_METADATA_SUCCESS`,
  FETCH_PRODUCT_METADATA_FAIL: `${actionTypePrefix}FETCH_PRODUCT_METADATA_FAIL`,

  SEND_OTP: `${actionTypePrefix}SEND_OTP`,
  SEND_OTP_SUCCESS: `${actionTypePrefix}SEND_OTP_SUCCESS`,
  SEND_OTP_FAIL: `${actionTypePrefix}SEND_OTP_FAIL`,

  VERIFY_OTP: `${actionTypePrefix}VERIFY_OTP`,
  VERIFY_OTP_SUCCESS: `${actionTypePrefix}VERIFY_OTP_SUCCESS`,
  VERIFY_OTP_FAIL: `${actionTypePrefix}VERIFY_OTP_FAIL`,
};




export interface IFetchProfilesByUserIdsAction {
  type: string;
  payload: {
    userIds: string[];
  };
}

export interface IFetchProfileDetailsAction {
  type: string;
  payload: {
    userId: string;
  }
}

export interface IUpdateProfileDetailsAction {
  type: string;
  payload: {
    userId: string;
    profile: any;
  }
}
export interface IMetaDataPayload {
  keys: string;
  scopes: string;
}

export interface IMetaDataListPayload {
  keys: string;
  response: any;
}

export const fetchProfilesByUserIds = (
  userIds: string[]
): IFetchProfilesByUserIdsAction => {
  return {
    type: profileActions.FETCH_PROFILES_BY_USER_IDS,
    payload: {
      userIds,
    },
  };
};

export const fetchProfilesByUserIdsSuccess = (profiles: IProfile[]) => {
  return {
    type: profileActions.FETCH_PROFILES_BY_USER_IDS_SUCCESS,
    payload: {
      profiles,
    },
  };
};

export const fetchProfilesByUserIdsFailure = (error: string) => {
  return {
    type: profileActions.FETCH_PROFILES_BY_USER_IDS_FAIL,
    payload: error,
  };
};

export const fetchProfileDetails = (userId: string): IFetchProfileDetailsAction => {
  return {
    type: profileActions.FETCH_PROFILE_DETAILS,
    payload: {
      userId
    },
  }
}

export const fetchProfileDetailsSuccess = (profile: ProfileModel) => {
  return {
    type: profileActions.FETCH_PROFILE_DETAILS_SUCCESS,
    payload: profile,
  }
}

export const fetchProfileDetailsFail = (error: string) => {
  return {
    type: profileActions.FETCH_PROFILE_DETAILS_FAIL,
    payload: error,
  }
}

// Create Profile
export const createProfile = (payload: ICreateProfilePayload) => {
  return {
    type: profileActions.CREATE_PROFILE,
    payload,
  }
}

export const createProfileSuccess = (profile: any) => {
  return {
    type: profileActions.CREATE_PROFILE_SUCCESS,
    payload: profile,
  }
}

export const createProfileFail = (error: string) => {
  return {
    type: profileActions.CREATE_PROFILE_FAIL,
    payload: error,
  }
}

export const updateProfileDetails = (profile: any, userId: string): IUpdateProfileDetailsAction => {
  return {
    type: profileActions.UPDATE_PROFILE_DETAILS,
    payload: {
      userId,
      profile
    },
  }
}

export const updateProfileDetailsSuccess = (profile: ProfileModel) => {
  return {
    type: profileActions.UPDATE_PROFILE_DETAILS_SUCCESS,
    payload: profile,
  }
}

export const updateProfileDetailsFail = (error: string) => {
  return {
    type: profileActions.UPDATE_PROFILE_DETAILS_FAIL,
    payload: error,
  }
}


export const fetchEducationQualifications = (): IFluxStandardAction => {
  return {
    type: profileActions.FETCH_EDUCATION_QUALIFICATION,
  }
}

export const fetchEducationQualificationsSuccess = (educatinQualifications: IUserEducationQualification[]) => {
  return {
    type: profileActions.FETCH_EDUCATION_QUALIFICATION_SUCCESS,
    payload: educatinQualifications,
  }
}

export const fetchEducationQualificationsFail = (error: string) => {
  return {
    type: profileActions.FETCH_EDUCATION_QUALIFICATION_FAIL,
    payload: error,
  }
}

export const fetchProfileByEmailId = (emailId: string) => {
  return {
    type: profileActions.FETCH_PROFILES_BY_EMAIL_ID,
    payload: {
      emailId
    }
  }
}

export const fetchProfileByEmailIdSuccess = (profile) => {
  return {
    type: profileActions.FETCH_PROFILES_BY_EMAIL_ID_SUCCESS,
    payload: profile
  }
}

export const fetchProfileByEmailIdFailure = (error: string) => {
  return {
    type: profileActions.FETCH_PROFILES_BY_EMAIL_ID_FAIL,
    payload: error
  }
}

export const fetchDepartments = () => {
  return {
    type: profileActions.FETCH_DEPARTMENTS,
  }
}

export const fetchDepartmentsSuccess = (departments: IDepartment[]) => {
  return {
    type: profileActions.FETCH_DEPARTMENTS_SUCCESS,
    payload: departments
  }
}

export const fetchDepartmentsFailure = (error: string) => {
  return {
    type: profileActions.FETCH_DEPARTMENTS_FAIL,
    payload: error
  }
}

export const showProfileModal = (isVisible: boolean) => {
  return ({
     type: profileActions.SHOW_PROFILE_MODAL,
    payload: {
      isVisible
    }
  });
}

//Map User Invitations
export const mapUserInvitation = (payload: IMapUserInvitationPayload): IMapUserInvitationPayloadAction => {
  return {
      type: profileActions.MAP_USER_INVITATION,
      payload
  }
}

export const mapUserInvitationSuccess = (data) => {
  return {
      type: profileActions.MAP_USER_INVITATION_SUCCESS,
      payload: data
  }
}

export const mapUserInvitationFail = (error:string) =>{
  return{
      type:profileActions.MAP_USER_INVITATION_FAIL,
      payload: error
  }
}


export const getProductMetaDataStart = (payload: IMetaDataPayload) => {
  return {
    type: profileActions.FETCH_PRODUCT_METADATA_START,
    payload
  }
}

export const getProductMetaDataSuccess = (payload: IMetaDataListPayload) => {
  return {
    type: profileActions.FETCH_PRODUCT_METADATA_SUCCESS,
    payload
  }
}

export const getProductMetaDataFail = (error: string) => {
  return {
    type: profileActions.FETCH_PRODUCT_METADATA_FAIL,
    payload: error
  }
}

export const sendOtp = (payload) => {
  return {
    type: profileActions.SEND_OTP,
    payload
  }
}
export const sendOtpSuccess = (payload) => {
  return {
    type: profileActions.SEND_OTP_SUCCESS,
    payload
  }
}
export const sendOtpFail = (error: string) => {
  return {
    type: profileActions.SEND_OTP_FAIL,
    payload: error
  }
}
export const verifyOtp = (payload) => {
  return {
    type: profileActions.VERIFY_OTP,
    payload
  }
}
export const verifyOtpSuccess = (payload) => {
  return {
    type: profileActions.VERIFY_OTP_SUCCESS,
    payload
  }
}
export const verifyOtpFail = (error: string) => {
  return {
    type: profileActions.VERIFY_OTP_FAIL,
    payload: error
  }
}
